import * as React from 'react';
import { Link, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';

function Index({ data, location }) {
  const content = data.homepage.nodes[0];
  const latestBlogPost = data.latestBlogPost.nodes[0];
  // eslint-disable-next-line max-len
  const featuredImgFluid = latestBlogPost.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData;

  return (
    <Layout location={location} title={content.frontmatter.title} bio={<Bio />}>
      <Seo title={content.frontmatter.title} />
      <section className="hello" dangerouslySetInnerHTML={{ __html: content.html }} />
      <hr />
      <section>
        <article
          className="post-list-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          <section className="blog-post-summary">
            <nav className="breadcrumb">
              <a href="/blog">Blog</a>
            </nav>
            {featuredImgFluid && (
            <div className="featured-image">
              <Link to={latestBlogPost.fields.slug} itemProp="url">
                <GatsbyImage image={featuredImgFluid} />
              </Link>
            </div>
            )}
            <article>
              <nav className="breadcrumb">
                <a href="/blog">Blog</a>
              </nav>
              <h3>
                <Link to={latestBlogPost.fields.slug} itemProp="url">
                  <span itemProp="headline">{latestBlogPost.frontmatter.title}</span>
                </Link>
              </h3>
              <small>{latestBlogPost.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: latestBlogPost.frontmatter.description || latestBlogPost.excerpt,
                }}
                itemProp="description"
              />
            </article>
          </section>
        </article>
      </section>
    </Layout>
  );
}

export default Index;

export const pageQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  homepage: allMarkdownRemark(filter: {fields: {slug: {eq: "/"}}}) {
    nodes {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
    }
  }
  latestBlogPost: allMarkdownRemark(
    filter: {fields: {slug: {ne: "/"}}}
    sort: {fields: [frontmatter___date], order: DESC}
    limit: 1
  ) {
    nodes {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 820, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`;
